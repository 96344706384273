/*global require, requirejs, define */
var require = {
    paths: {
        //Vendor
        'bootstrap-collapse': 'Vendor/Bootstrap/collapse',
        'bootstrap-dropdown': "Vendor/Bootstrap/dropdown",
        'bootstrap-transition': "Vendor/Bootstrap/transition",
        'bootstrap-tooltip': 'Vendor/Bootstrap/tooltip',
        'bootstrap-popover': 'Vendor/Bootstrap/popover',
        'bootstrap-modal': 'Vendor/Bootstrap/modal',
        'bootstrap-tabs': 'Vendor/Bootstrap/tab',
        'jquery': 'Vendor/jquery',
        'isMobile': 'Vendor/isMobile',
        'sidr': 'Vendor/jquery.sidr.min',
        'modernizr': 'Vendor/modernizr',
        'jqueryValidate': 'Vendor/jquery.validate',
        'jqueryUnobtrusive': 'Vendor/jquery.validate.unobtrusive',
        'underscore': 'Vendor/underscore-min',

        //Plugins
        'swiper': 'Plugins/swiper.jquery',

        //Modules
        'helpers': 'Modules/helpers',
        'loginForm': 'Modules/login-form',
        'profile': 'Modules/profile',
        'constants': 'Modules/constants',
        'swiper-custom': 'Modules/swiper-custom',
        'helpingHandsModal': 'Modules/helping-hands-modal',
        'analytics': 'Modules/analytics',
        'join': 'Modules/join-saxendacare',
    },
    shim: {
        'bootstrap-transition': ['jquery'],
        'bootstrap-collapse': ['bootstrap-transition'],
        'bootstrap-dropdown': ['jquery'],
        'bootstrap-tooltip': ['jquery'],
        'bootstrap-popover': ['bootstrap-tooltip'],
        'bootstrap-modal': ['jquery'],
        'bootstrap-tabs': ['jquery'],
        'swiper': ['jquery'],
        'sidr': ['jquery'],
        'jqueryValidate': ['jquery'],
        'jqueryUnobtrusive': ['jqueryValidate', 'jquery'],
        'underscore': {
            exports: '_'
        }
    }
};

var attrMain = document.body.attributes['data-main'];

//requirejs main module can be specified through data-main attribute
if (attrMain) {
    var jsMain = attrMain.value;
    //remove .js extension if present
    var matches = jsMain.match(/^([\w-]+)\.js$/);
    if (matches) {
        jsMain = matches[1];
    }
    require.deps = [jsMain];
}

requirejs.config(require);

requirejs.onError = function (err) {
    'use strict';
    console.error(err.stack);
};

//add shared modules in the common dependencies
requirejs([
    'jquery',
    'isMobile',
    'helpers',
    'bootstrap-collapse',
    'bootstrap-dropdown',
    'bootstrap-modal',
    'bootstrap-tooltip',
    'loginForm',
    'constants',
    'underscore',
    'swiper',
    'sidr',
    'modernizr',
    'jqueryValidate',
    'jqueryUnobtrusive'
], function ($,
             isMobile,
             helpers,
             bootstrapCollapse,
             bootstrapDropdown,
             bootstrapModal,
             bootstrapTooltip,
             loginForm,
             constants,
             _) {
    'use strict';

    var $navbarPrimaryLoginOptionsDesktop = $('.nav-desktop .js-navbar-primary-login-options'),
        $dropdownMenus = $('.nav-desktop .dropdown-menu'),
        desktopMenuActive = false;

    var initMobileMenu = function () {

        $('.navbar-toggle').sidr();
        // Make sure the menu is closed when resizing between mobile and desktop size, otherwise the
        // menu stays open and obscures the menu toggle button.
        $.sidr('close');

    };

    var initDesktopMenu = function () {

        if ($(window).width() >= constants.SCREEN_MD_MIN) {

            // Remove mobile menu
            setTimeout(function () {
                $.sidr('close');
                $('body').attr('style', '');
            }, 200);

            // Set the width of dropdowns to match either its parent or its parent + parents sibling
            $dropdownMenus.each(function () {
                var $this = $(this),
                    $parent = $this.parents('li'),
                    parentOuterWidth = $parent.outerWidth(),
                    newWidth;

                if ($parent.hasClass('aboutsaxenda')) {
                    newWidth = parentOuterWidth + $('.saxendacare').outerWidth();
                } else if ($parent.hasClass('saxendacare')) {
                    newWidth = parentOuterWidth + $('.motivationandgoals').outerWidth();
                } else {
                    newWidth = parentOuterWidth;
                }

                $this.css('width', newWidth);
            });
        }

    };

    var showLoginOptions = function () {
        if ($(window).width() >= constants.SCREEN_MD_MIN) {
            $navbarPrimaryLoginOptionsDesktop.addClass('active');
        }
    };

    var hideLoginOptions = function () {
        if ($(window).width() >= constants.SCREEN_MD_MIN) {
            $navbarPrimaryLoginOptionsDesktop.removeClass('active');
        }
    };

    var calculateLayout = function () {
        $.sidr('close');

        if ($(window).width() < constants.SCREEN_MD_MIN) {
            // Set to false so that if screen is resized to desktop then we run the initDesktopMenu function again
            // to remove mobile menu etc.
            desktopMenuActive = false;
        } else {
            if (!desktopMenuActive) {
                initDesktopMenu();
                desktopMenuActive = true; // Prevent running initDesktopMenu again if still at desktop size
            }
        }
    };

    var bindEvents = function () {

        var $loginDesktop = $('.header-links .js-navbar-primary-logged-in'),
            $loginMobile = $('.nav-mobile .js-navbar-primary-logged-in'),
            $loginOptionsDesktop = $('.nav-desktop .js-navbar-primary-login-options'),
            $loginOptionsMobile = $('.nav-mobile .js-navbar-primary-login-options'),
            loginOptionsTimer;

        if (!Modernizr.touch) {
            /**
             * On hover functionality for hovering over the username
             */
            $loginDesktop
                .on('mouseover', function () {
                    clearTimeout(loginOptionsTimer);
                    showLoginOptions();
                })
                .on('mouseleave', function () {
                    /**
                     * Once the mouse has left the login name link, give the user 1 sec to move their
                     * mouse over the options menu, otherwise close the menu.
                     */
                    loginOptionsTimer = setTimeout(hideLoginOptions, 800);
                });

            /**
             * If user moves mouse within specified time to hover over the options menu, clear the timer so
             * the options stay open and only close the menu after the mouse has left.
             */
            $loginOptionsDesktop
                .on('mouseover', function () {
                    clearTimeout(loginOptionsTimer);
                })
                .on('mouseleave', function () {
                    loginOptionsTimer = setTimeout(hideLoginOptions, 800);
                });

        } else {
            /**
             * Add different handlers for touch devices
             */
            $loginDesktop
                .on('touchstart', function () {
                    $loginOptionsDesktop.toggleClass('active');
                });

            $loginMobile.on('touchstart', function () {
                $loginOptionsMobile.toggleClass('active');
            });
        }

        // Modal for external links
        $('.js-external-link-modal').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            if ($.sidr) {
                $.sidr('close');
            }

            var getLink = e.currentTarget.href;

            if (typeof getLink !== "undefined" && $('.js-add-external-link')) {
                $('.js-add-external-link').attr('href', getLink);
                $('#modal-external-link').modal();
            }
        });

        // Close modal after external link is clicked
        $('.js-add-external-link').on('click', function (e) {
            $('#modal-external-link').modal('hide');
        });

        // Add window resize handler
        $(window).on('resize', _.debounce(calculateLayout, 150));

        loginForm.init();

    };

    var _init = function () {
        initMobileMenu();
        initDesktopMenu();
        bindEvents();
    };

    _init();

});
define("common", function(){});

