define('helpers',['jquery'],
	function($) {
	'use strict';
	function Helpers() {

		var self = this;

		self.$body = $('body');
		self.$html = $('html');
		self.$document = $(document);
		self.$window = $(window);

		self.getQueryStrings = function() {
			var queryStrings = location.search.substr(1).split('&');
			var queryStringObj = {};
			for(var i = 0, len = queryStrings.length; i < len; i++) {
				var split = queryStrings[i].split('=');
                queryStringObj[split[0]] = split[1];
			}
            return queryStringObj;
		};

		self.debounce = function (func, wait, immediate) {
			var timeout;
			return function() {
				var context = this,
					args = arguments,
					callNow = immediate && !timeout,
					later = function() {
						timeout = null;
						if (!immediate) {
							func.apply(context, args);
						}
					};
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) {
					func.apply(context, args);
				}
			};
		};

		// Cookies
        self.createCookie = function(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        };

        self.readCookie = function(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        };

        self.eraseCookie = function(name) {
            createCookie(name,"",-1);
        };

	}

	return new Helpers();
});
