define('constants',[], function () {
	'use strict';
	var SCREEN_SM_MIN = 768,
		SCREEN_MD_MIN = 992;

	return {
		SCREEN_SM_MIN: SCREEN_SM_MIN,
		SCREEN_MD_MIN: SCREEN_MD_MIN
	}
});
