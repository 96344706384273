define('analytics',['jquery'],
	function($) {
	'use strict';
	function Analytics() {

		var self = this;

        self.$cta = $('.cta-link');

        function trackCTAEvent() {
            self.$cta.on('click', function (e) {
                if (!window.dataLayer) return;
                window.dataLayer.push({
                    'event':'cta_click',
                    'cta_text': e.currentTarget.innerText,
                    'directed_page': e.currentTarget.href
                });
            });
        }

        self.trackUserEvent = function trackUserEvent(cat, action, label, callback) {
            console.log('tracking:', cat, action, label);
            if (!window.dataLayer) return;
            window.dataLayer.push({
                'event': 'User event',
                cat,
                action,
                label
            });
            if (callback) {
                callback();
            }
        };

        self.trackLoginEvent = function trackLoginEvent(isPatient, id) {
            if (!window.dataLayer) return;
            window.dataLayer.push({
                'event': 'login_complete',
                'patient_or_hcp': isPatient ? 'patient': 'hcp',
                'login_detail': id
            });
        }

        trackCTAEvent();
	}

	return new Analytics();
});
