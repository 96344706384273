define('loginForm',[
        'jquery',
        'helpers',
        'analytics',
        'bootstrap-popover'
    ],
    function ($, Helpers, Analytics) {
        'use strict';

        var formConfig = {
            'individualFieldClass': '.individual-field',
            'isValidClassName': 'is-valid',
            'propDisabled': 'disabled'
        };

        var $loginForm = $('.public-login-form'),
            $barcodeLoginForm = $('.public-login-form-barcode'),
            $hcpLoginForm = $('.hcp-login-form'),
            $providerLoginForm = $('.hcp-login-form-provider'),
            $idField = $('#patient-id, #barcode-id'),
            $loginIndividualFields = $('.login-individual-fields ' + formConfig.individualFieldClass);

        // Returns a boolean value based on inputs passed in as being valid or invalid
        function _validateInputs($inputs) {
            var valid = true;
            $inputs.each(function() {
                return valid = $(this).hasClass('valid');
            });
            return valid;
        }

        // Moves the focus/select to the next input or if backspace will clear the current input and move to the previous input
        // If all the inputs are filled it submits the form
        function _focusInputs(e) {
            e.preventDefault();
            var currInput = this;
            var $parentForm = $(e.target).parents('form');
            var $siblingInputs =  $(e.currentTarget).parent().children(formConfig.individualFieldClass);

            // If ctrl, shift, alt or tab pressed then don't do anything
            if([16, 17, 18, 19, 9].indexOf(e.which) >= 0) {
                return;
            }

            // If backspace, selects previous input if it exists
            if(e.which === 8) {
                if(!currInput.value) {
                    var prevInput = $siblingInputs.get($siblingInputs.index(currInput) - 1);
                    if(prevInput && $siblingInputs.index(currInput) - 1 >= 0) {
                        // Android needs a click event to show the keyboard when focusing an input
                        // http://stackoverflow.com/questions/15457783/can-i-trigger-android-soft-keyboard-to-open-via-javascript-without-phonegap
                        // setTimeout and setSelectionRangeFix for iOS to select the text when focusing an input
                        // http://stackoverflow.com/questions/10452391/is-it-possible-to-select-all-input-text-on-iphone-device-when-focus-click
                        setTimeout(function() {
                            $(prevInput).trigger('click');
                        });
                    }
                }
            } else {
                // Removes additional characters entered
                currInput.value = currInput.value.slice(0, 1);
                var nextInput = $siblingInputs.get($siblingInputs.index(currInput) + 1);
                if($(currInput).valid() && nextInput) {
                    setTimeout(function () {
                        $(nextInput).trigger('click');
                    });
                }
            }

            if(_validateInputs($siblingInputs)) {
                submitForm($parentForm);
            }

        }

        // Concatenates all the values of specified input collection and returns the value
        function _concatenateId(idCollection) {
            var loginId = '';
            idCollection.each(function () {
                loginId += this.value.slice(0, 1);
            });
            return loginId;
        }

        function setupFormValidation() {
            $loginForm.validate({
                errorPlacement: function (error, element) {
                    error.insertBefore(".public-login--forgot-password");
                },
                submitHandler: function (form) {
                    Analytics.trackLoginEvent(true, $(form).find('#patient-id').val());
                    form.submit();
                }
            });
            $barcodeLoginForm.validate({
                errorPlacement: function (error, element) {
                    error.appendTo(element.parent());
                },
                submitHandler: function (form) {
                    Analytics.trackLoginEvent(true, $(form).find('#barcode-id').val());
                    form.submit();
                }
            });
            $hcpLoginForm.validate({
                submitHandler: function (form) {
                    Analytics.trackLoginEvent(false, $(form).find('#hcp_id').val());
                    form.submit();
                }
            });
            $providerLoginForm.validate({
                messages: {
                    Id: {
                        alphanumeric: 'Only alphanumeric characters are allowed'
                    }
                },
                submitHandler: function (form) {
                    Analytics.trackLoginEvent(false, $(form).find('#provider_number').val());
                    if ($(form).find('#provider_number').val() === "lira3mg") {
                        Analytics.trackUserEvent('Login Type', 'Form Login', 'lira3mg login', function() {
                            form.submit();
                        });
                    } else {
                        form.submit();
                    }
                }
            });

            $(formConfig.individualFieldClass + '[type=number]').each(function() {
                $(this).rules('add', {
                    required: true,
                    maxlength: 1,
                    messages: {
                        required: "This field requires a digit"
                    }
                });
            });

            // Need to add the rules to the field ID as the fields name attr is the same for both Forms
            if ($providerLoginForm.length && $hcpLoginForm.length) {
                $('#hcp_id').rules('add', {required: true});
                $('#provider_number').rules('add', {
                    alphanumeric: true,
                    required: true
                });
            }

            $.validator.messages.maxlength = 'Please enter no more than 1 character in each field';
            $.validator.addMethod('alphanumeric', function(value, element) {
                return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
            });
        }

        // Submits the form, disables the input fields so they aren't in the POST
        function submitForm(form) {
            var $parentForm = form;
            var $idCollection = $parentForm.find('.login-individual-fields ' + formConfig.individualFieldClass);

            // disable the id fields so they don't get submitted
            $idCollection.prop(formConfig.propDisabled, true);
            $idField.attr('value', _concatenateId($idCollection));
            $parentForm.submit();
            $idCollection.prop(formConfig.propDisabled, false);
        }

        // Gets all other forms and clears, then removes any validation.
        function clearOtherForms($currForm) {
            var otherForms = $('form').not($currForm);
            otherForms.each(function(index, form) {
                // clear the form
                form.reset();
                // reset validator
                $(form).removeClass(formConfig.isValidClassName).validate().resetForm();
            });
        }

        function _checkValidForm() {
            var $currForm = $(event.currentTarget);
            clearOtherForms($currForm);

            if($currForm.valid()) {
                $currForm.addClass(formConfig.isValidClassName);
            } else {
                $currForm.removeClass(formConfig.isValidClassName);
            }
        }

        function bindEvents() {
            $('[data-toggle="popover"]').popover();
            $loginIndividualFields.on('keyup', _focusInputs);
            // Android needs a click event to show the keyboard when focusing an input with JS
            // http://stackoverflow.com/questions/15457783/can-i-trigger-android-soft-keyboard-to-open-via-javascript-without-phonegap
            $loginIndividualFields.click(function(e){
                var $self = $(this);
                $self.focus();
                $self.select();
                if(!$self.is('input[type="number"]')) {
                    $self[0].setSelectionRange(0,9999);
                }
            });
            $hcpLoginForm.add($providerLoginForm).on('keyup', _checkValidForm);
        }

        function init() {
            setupFormValidation();
            bindEvents();
        }

        return {
            init: init
        }
    }
);
